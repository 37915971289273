import React, { ReactElement } from 'react';
import { Box, Hide, Show } from '@chakra-ui/react';
import { SOFT_HYPHEN } from '../../../utils/string';
import { Locale } from '../../../utils/types';
import { By } from '../../By';
import { DateTime } from '../../DateTime';
import { Heading } from '../../Heading';
import { NestedLocale } from '../../NestedLocale';
import { Paragraph } from '../../Paragraph';
import { SmartLineWrap } from '../../SmartLineWrap';
import { PageVariant } from '../misc';

type TitleAreaProps = {
  variant: PageVariant;
  title: string;
  subtitle?: string;
  blogPostData?: {
    language: Locale;
    createdAt: string;
    authorNames?: string[];
  };
  eventPostData?: {
    language: Locale;
    start: string;
    end: string;
    location?: string;
  };
};

export const TitleArea = ({
  variant,
  title,
  subtitle,
  blogPostData,
  eventPostData,
}: TitleAreaProps): ReactElement => (
  <Box marginY={variant === 'frontpage' ? 0 : '4em'} textAlign={'center'}>
    <NestedLocale
      componentLocale={
        variant === 'blogPost' && blogPostData
          ? blogPostData.language
          : variant === 'eventPost' && eventPostData
          ? eventPostData.language
          : undefined
      }
    >
      <Show breakpoint={'(min-width: 400px)'}>
        <Heading
          level={
            variant === 'frontpage'
              ? 1
              : variant === 'generic' ||
                variant === 'blogCollection' ||
                variant === 'eventCollection'
              ? 2
              : 3
          }
          {...(variant === 'frontpage' ? { marginTop: 0 } : {})}
        >
          <SmartLineWrap centered>
            {title.replace('Trans*Creative', `Trans*${SOFT_HYPHEN}Creative`)}
          </SmartLineWrap>
        </Heading>
      </Show>
      <Hide breakpoint={'(min-width: 400px)'}>
        <Heading
          level={
            variant === 'frontpage'
              ? 1
              : variant === 'generic' ||
                variant === 'blogCollection' ||
                variant === 'eventCollection'
              ? 2
              : 3
          }
        >
          {title.replace('Trans*Creative', `Trans*${SOFT_HYPHEN}Creative`)}
        </Heading>
      </Hide>
      {subtitle && (
        <>
          <Show above={'xs'}>
            <Heading level={5} as={'p'}>
              <SmartLineWrap centered>{subtitle}</SmartLineWrap>
            </Heading>
          </Show>
          <Hide above={'xs'}>
            <Heading level={5} as={'p'}>
              {subtitle}
            </Heading>
          </Hide>
        </>
      )}
    </NestedLocale>
    {variant === 'blogPost' && blogPostData && (
      <Box marginTop={'1.5em'}>
        <DateTime start={blogPostData.createdAt} dateOnly condensed />
        <By authors={blogPostData.authorNames} />
      </Box>
    )}
    {variant === 'eventPost' && eventPostData && (
      <Box marginTop={'1.5em'}>
        <Paragraph fontSize={'0.9em'} margin={0}>
          {eventPostData.location}
        </Paragraph>
        <DateTime
          start={eventPostData.start}
          end={eventPostData.end}
          condensed
        />
      </Box>
    )}
  </Box>
);
