import React, { Fragment, ReactElement, useMemo } from 'react';
import { SmartWrap, SmartWrapProps } from '../SmartWrap';

export const splitter = (string: string): string[] => {
  if (!string) {
    return [];
  }

  const half = Math.ceil(string.length / 2);
  const first = string.slice(0, half);
  const second = string.slice(half);
  const firstHalfLastSpace = first.lastIndexOf(' ');
  const secondHalfFirstSpace = second.indexOf(' ');
  const firstHalfSpaceDistance = first.length - firstHalfLastSpace;
  const secondHalfSpaceDistance = 0 - secondHalfFirstSpace;

  if (firstHalfLastSpace === -1 && secondHalfFirstSpace === -1) {
    return [string];
  } else if (
    (firstHalfLastSpace !== -1 && secondHalfFirstSpace === -1) ||
    firstHalfSpaceDistance < secondHalfSpaceDistance
  ) {
    return [
      splitter(string.slice(0, firstHalfLastSpace)),
      splitter(string.slice(firstHalfLastSpace + 1)),
    ].flat();
  } else {
    return [
      splitter(string.slice(0, half + secondHalfFirstSpace)),
      splitter(string.slice(half + secondHalfFirstSpace + 1)),
    ].flat();
  }
};

export type SmartLineWrapProps = {
  children: string;
  centered?: boolean;
  spacingX?: SmartWrapProps['spacingX'];
};

export const SmartLineWrap = ({
  children,
  centered,
  spacingX = '0.2ch',
}: SmartLineWrapProps): ReactElement | null => {
  const parts = useMemo(
    () => (!children ? [] : splitter(children)),
    [children]
  );

  return (
    <SmartWrap
      spacingX={spacingX}
      justify={centered ? 'center' : undefined}
      align={centered ? 'center' : undefined}
    >
      {parts.map((part, i) => (
        <Fragment key={part + i}>{part}</Fragment>
      ))}
    </SmartWrap>
  );
};
