import React, { ReactElement } from 'react';
import { graphql, PageProps } from 'gatsby';
import { TitleArea } from '../components/TemplateHelpers/1-TitleArea';
import { ContentArea } from '../components/TemplateHelpers/3-ContentArea';
import { BlogPostQuery } from './BlogPost';

export type BlogCollectionQuery = {
  contentfulPageBlogCollection: {
    title: string;
    subtitle?: string;
    slug: string;
  };
  allContentfulPageBlogPost: {
    nodes: BlogPostQuery['contentfulPageBlogPost'][];
  };
};

const BlogCollection = ({
  data,
}: PageProps<BlogCollectionQuery>): ReactElement => {
  const { contentfulPageBlogCollection, allContentfulPageBlogPost } = data;
  const { title, subtitle, slug } = contentfulPageBlogCollection;

  return (
    <>
      <TitleArea variant={'blogCollection'} title={title} subtitle={subtitle} />
      <ContentArea
        variant={'blogCollection'}
        blogPosts={allContentfulPageBlogPost.nodes}
        parentSlug={slug}
      />
    </>
  );
};

export default BlogCollection;

export const query = graphql`
  query ($id: String!, $locale: String!) {
    contentfulPageBlogCollection(id: { eq: $id }) {
      ...BlogCollection
    }
    allContentfulPageBlogPost(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        ... on ContentfulPageBlogPost {
          ...BlogPostLink
        }
      }
    }
  }
`;
